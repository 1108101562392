<template>
    <div id="county-contact">
        <Form
            ref="form"
            @submit="sendMessage"
            data-request="contactform::onSend"
            data-request-validate
            data-request-flash
            v-slot="{ errors }"
        >
            <div class="row form-group">
            <div class="col-lg-6 col-md-12 mb-3 mb-lg-0">
                <Field
                type="text"
                name="contact.name"
                v-model="name"
                class="form-control"
                placeholder="Nume si prenume"
                :rules="validateName"
                :class="{ error: errors['contact.name'] }"
                />
                <ErrorMessage class="form-error" name="contact.name" />
            </div>
            <div class="col-lg-6 col-md-12">
                <Field
                type="text"
                name="contact.address"
                value=""
                class="form-control"
                placeholder="Adresa"
                />
            </div>
            </div>
            <div class="row form-group">
                <div class="col-lg-6 col-md-12 mb-3 mb-lg-0">
                <Field
                type="text"
                name="contact.city"
                class="form-control"
                placeholder="Oras"
                :rules="validateCity"
                :class="{ error: errors['contact.city'] }"
                />
                <ErrorMessage class="form-error" name="contact.city" />
            </div>
            <div class="col-lg-6 col-md-12">
                <Field
                type="text"
                name="contact.county"
                :value="county_name"
                class="form-control"
                placeholder="Judet"
                :rules="validateCounty"
                :class="{ error: errors['contact.county'] }"
                />
                <ErrorMessage class="form-error" name="contact.county" />
            </div>
            </div>
            <div class="row form-group">
            <div class="col-lg-6 col-md-12 mb-3 mb-lg-0">
                <Field
                type="text"
                name="contact.phone"
                value=""
                class="form-control"
                placeholder="Telefon"
                :rules="validatePhone"
                :class="{ error: errors['contact.phone'] }"
                />
                <ErrorMessage class="form-error" name="contact.phone" />
            </div>
            <div class="col-lg-6 col-md-12">
                <Field
                type="text"
                name="contact.email"
                value=""
                class="form-control"
                placeholder="Email"
                />
            </div>
            </div>
            <div class="row form-group">
            <div class="col-md-12">
                <Field
                as="textarea"
                name="contact.message"
                placeholder="Mesaj:"
                rows="4"
                cols="40"
                value=""
                class="form-control"
                :rules="validateMessage"
                :class="{ error: errors['contact.message'] }"
                />
                <ErrorMessage class="form-error" name="contact.message" />
            </div>
            </div>

            <div class="row form-group pt20">
            <div class="col-lg-12 recaptcha">
                <Field
                v-if="recaptchaSiteKey?.recaptchaSiteKey"
                name="recaptcha"
                :rules="validateRecap"
                >
                <vue-recaptcha
                    :sitekey="recaptchaSiteKey.recaptchaSiteKey"
                    language="ro"
                    @verify="handleSuccess"
                    @expired="handleExpired"
                ></vue-recaptcha>
                </Field>
                <ErrorMessage class="form-error" name="recaptcha" />
            </div>
            </div>
            
            <div class="row form-group pt20">
            <div class="col-lg-12">
                <Field
                type="checkbox"
                name="policy"
                value="policy"
                id="checkout_agree2"
                :rules="validateCheckbox"
                />
                <label class="checkout_value inline" for="checkout_agree2"
                >Sunt de acord cu
                <a href="/politica-de-confidentialitate-a-datelor.html" target="_blank"
                    >politica de confidentialitate a datelor
                    <span class="steluta">*</span></a
                ></label
                >
                <br />
                <ErrorMessage class="form-error" name="policy" />
            </div>
            </div>
            <div class="row form-group pt20">
            <div class="col-lg-6">
                <div class="row">
                <div class="col-12">
                    <input
                    type="submit"
                    name="trimite"
                    value="Trimite mesaj"
                    class="btn btn-red form-btn contact-form-btn"
                    />
                </div>
                </div>
            </div>
            </div>
        </Form>
        <ContactResponseModal />
    </div>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import ContactResponseModal from "@/components/contact/ContactResponseModal.vue";
import { VueRecaptcha } from "vue-recaptcha";

export default {
  name: "Contact",

  data() {
    return {
      name: "",
      hasError: false,
      submitted: false,
      recaptcha: false,
      recaptchaToken: "",
    };
  },
  components: {
    Form,
    Field,
    ErrorMessage,
    VueRecaptcha,
    ContactResponseModal,
  },
  computed: {
    recaptchaSiteKey() {
      return this.$store.getters["shop/currency"];
    },
  },
  props: ['county_name'],
  methods: {
    async sendMessage(values, { resetForm }) {
      // Wait until recaptcha has been loaded.
      // await this.$recaptchaLoaded();
      // Execute reCAPTCHA with action "login".
      // const token = await this.$recaptcha("login");
    
      values.contact.token = this.recaptchaToken;
      values.contact.address = values.contact.address + ', ' + values.contact.city + ', jud.' + values.contact.county;
      this.$store.dispatch("forms/responseContact", true);
      this.$store.dispatch("forms/sendContactForm", values.contact).then((response) => {
        if (response?.status === "success") {
          resetForm();
        }
      });
    },
    handleSuccess(value) {
      this.recaptcha = true;
      this.recaptchaToken = value;
      this.$refs.form.validateField("recaptcha");
      // return value;
    },
    handleExpired() {
      this.recaptcha = false;
      this.recaptchaToken = "";
      this.$refs.form.validateField("recaptcha");
    },
    validateName(value) {
      if (!value) {
        return "NUMELE ESTE OBLIGATORIU!";
      }
      return true;
    },
    validateCity(value) {
      if (!value) {
        return "ORASUL ESTE OBLIGATORIU!";
      }
      return true;
    },
    validateCounty(value) {
      if (!value) {
        return "JUDETUL ESTE OBLIGATORIU!";
      }
      return true;
    },
    validatePhone(value) {
      if (!value) {
        return "NUMAR TELEFON (VALID) OBLIGATORIU!";
      }
      return true;
    },
    validateCheckbox(value) {
      if (!value) {
        return "ESTE NECESAR SA BIFATI CASUTA PENTRU A CONTINUA!";
      }
      return true;
    },
    validateMessage(value) {
      if (!value) {
        return "MESAJUL ESTE OBLIGATORIU!";
      }
      return true;
    },
    validateRecap() {
      if (!this.recaptcha) {
        return "RECAPTCHA NU ESTE VALIDAT";
      }
      return true;
    },
  }
}
</script>

<style>
#county-contact {
    padding-bottom: 100px;
}
#county-contact input.form-control {
    font-family: Raleway;
    font-size: 13px;
    height: 40px;
    border-radius: 20px;
    padding: 13px 31px 13px 20px;
    color: #6a6666;
    background-color: transparent;
}
#county-contact textarea.form-control {
    font-family: Raleway;
    font-size: 13px;
    height: 150px;
    border-radius: 20px;
    padding: 13px 31px 13px 20px;
    color: #6a6666;
    background-color: transparent;
}
#county-contact .checkout_value {
    font-family: Raleway Bold;
    font-size: 13px;
    margin-left: 15px;
}
#county-contact input[type=checkbox] {
    appearance: initial;
    -moz-appearance: initial;
    -webkit-appearance: initial;
    height: 19px;
    width: 19px;
    border: 1px solid #979797;
    background: #FFFFFF;
    /* border-radius: 7px; */
    margin-bottom: -5px;
    outline: none;
    position: relative;
    border-radius: 50%;
}
#county-contact input[type=checkbox]:checked::after {
    content: '';
    color: #97040c;
    font-size: 30px;
    font-weight: 900;
    position: absolute;
    left: 50%;
    top: 30%;
    -webkit-transform: translate(-50%,-50%);
    -moz-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    background-color: #9d1300;
    height: 13px;
    width: 13px;
    border-radius: 50%;
    top: 50%;
}
#county-contact .contact-form-btn {
    font-family: Montserrat Semibold;
    font-size: 15px;
    line-height: 2.25;
    color: #fff;
    height: 44;
    background-color: #97040c;
    border: 1px solid #97040c;  
    border-radius: 20px;
    width: 100%;
}

@media (max-width:576px) {
    #county-contact .checkout_value {
        font-size: 11px;
    }
}
</style>